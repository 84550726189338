import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { fetchMatchCenterEvents } from "../../../api/matchCenter";
import { Dialog } from "@mui/material";
import EditContentTabs from "../../editContentTabs";
import { format } from "date-fns";
import {
  fetchMatchCenterContent,
  setMatchCenterContent,
} from "../../../api/matchCenter";
import { PERMISSIONS } from "../../../utils/constants";
import hasPermissions from "../../../utils/hasPermissions";

const createColumns = (handleOpenDialog) => {
  return [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "league",
      headerName: "League",
      flex: 1,
    },
    {
      field: "competition",
      headerName: "Competition",
      flex: 2,
      sortable: false,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 2,
      renderCell: (row) => (
        <span>
          {format(new Date(row.row.date), "dd MMM yyyy HH:mm", {
            timeZone: "UTC",
          })}{" "}
          UTC
        </span>
      ),
    },
    {
      field: "firstTeam",
      headerName: "Team 1",
      flex: 2,
      sortable: false,
      renderCell: (row) => <span>{row.row.competitors?.[0]?.name}</span>,
    },
    {
      field: "secondTeam",
      headerName: "Team 2",
      flex: 2,
      sortable: false,
      renderCell: (row) => <span>{row.row.competitors?.[1]?.name}</span>,
    },
    {
      field: "winProbability",
      headerName: "Win probability",
      flex: 1,
      sortable: false,
      renderCell: (row) => <span>{row.row.competitorOneWinChance || "-"}</span>,
    },
    {
      field: "place",
      headerName: "Place",
      flex: 2,
      sortable: false,
      renderCell: (row) => <span>{row.row.place || "-"}</span>,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1.2,
      sortable: false,
      renderCell: (row) => (
        <Button
          variant="contained"
          color="primary"
          disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_MATCH_CENTER)}
          onClick={() => handleOpenDialog(row.row)}
        >
          Edit
        </Button>
      ),
    },
  ];
};

const keys = {
  football: [
    { title: "Match Details", contentPostfix: "" },
    { title: "Summary", contentPostfix: "summary" },
    { title: "Statistics", contentPostfix: "statistics" },
    { title: "Lineups", contentPostfix: "lineups" },
    { title: "Head to Head", contentPostfix: "h2h" },
    { title: "Score", contentPostfix: "score" },
  ],
  kabaddi: [
    { title: "Match Details", contentPostfix: "" },
    { title: "Highlights", contentPostfix: "highlights" },
  ],
  cricket: [
    { title: "General", contentPostfix: "" },
    { title: "Squads", contentPostfix: "squads" },
    { title: "Highlights", contentPostfix: "highlights" },
    { title: "Details", contentPostfix: "match-details" },
    { title: "Scorecard", contentPostfix: "scorecard" },
  ],
}

export default function MatchTable({ projectId, sport, version }) {
  const [events, setEvents] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [currentItem, setCurrentItem] = useState(null);
  const [sortOptions, setSortOptions] = useState([
    { field: "date", sort: "desc" },
  ]);
  const [filters, setFilters] = useState({
    league: "",
    competition: "",
    status: 0,
    url: "",
  });
  const [tempFilters, setTempFilters] = useState({
    league: "",
    competition: "",
    status: 0,
    url: "",
  });
  const [dialog, setDialog] = useState(false);

  const closeDialog = () => {
    setDialog(false);
  };

  const statusList = [
    { text: "All", value: 0 },
    { text: "Finished", value: "Finished" },
    { text: "Live", value: "Live" },
    { text: "Upcoming", value: "Upcoming" },
  ];

  const createParams = useCallback(() => {
    const filterParams = [];
    Object.keys(filters).forEach((key) => {
      if (!filters[key]) return;
      if (key === "status") {
        if (filters[key] !== 0) {
          filterParams.push({ key, value: filters[key] });
        }
      } else {
        filterParams.push({ key, value: filters[key] });
      }
    });
    const pageParams = [
      { key: "take", value: pageSize },
      { key: "skip", value: page * pageSize },
    ];
    const sortParams = [
      { key: "sortBy", value: sortOptions?.[0]?.field || "date" },
      { key: "sortDirection", value: sortOptions?.[0]?.sort || "desc" },
    ];
    const sourceParams = [
      { key: "source", value: sport.source },
      { key: "version", value: version },
    ];
    return [...filterParams, ...pageParams, ...sortParams, ...sourceParams];
  }, [filters, page, pageSize, sortOptions, version, sport.source]);

  const fetchEventList = useCallback(async () => {
    if (!projectId) return;
    const queryParams = createParams();
    const events = await fetchMatchCenterEvents(projectId, sport.id, queryParams);
    setEvents(events.data);
    setTotal(events.total);
  }, [createParams, sport, projectId]);

  const handleFiltersChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setTempFilters({ ...tempFilters, [name]: value });
    },
    [tempFilters]
  );

  const handleOpenDialog = useCallback((item) => {
    setCurrentItem(item);
    setDialog(true);
  }, []);

  const handleSearchClick = useCallback(() => {
    setPage(0);
    setFilters(tempFilters);
  }, [tempFilters]);

  const handleSortModelChange = useCallback((sortModel) => {
    setPage(0);
    setSortOptions([...sortModel]);
  }, []);

  const handleResetFilters = useCallback(async () => {
    setFilters({ league: "", competition: "", status: 0, url: "" });
    setTempFilters({
      league: "",
      competition: "",
      status: 0,
      url: "",
    });
    setPage(0);
    await fetchEventList();
  }, [fetchEventList]);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPage(0);
    setPageSize(newPageSize);
  }, []);

  const handleGetContent = useCallback(async (contentKey) => {
    const data = await fetchMatchCenterContent(projectId, sport.id, contentKey);
    return data;
  }, [projectId, sport]);

  const handleSetContent = useCallback(async (contentKey, content) => {
    const data = await setMatchCenterContent(projectId, sport.id, contentKey, content);
    return data;
  }, [projectId, sport]);

  useEffect(() => {
    fetchEventList();
  }, [fetchEventList]);

  const columns = useMemo(() => {
    return createColumns(handleOpenDialog);
  }, [handleOpenDialog]);
  return (
    <div>
      <Dialog fullScreen open={dialog} onClose={closeDialog}>
        {sport && sport.id && <EditContentTabs
          sport={sport}
          item={currentItem}
          keys={keys[sport.id]}
          closeDialog={closeDialog}
          getContent={handleGetContent}
          setContent={handleSetContent}
        />}
      </Dialog>
      {events && (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              height: "100px",
            }}
          >
            filter by
            <TextField
              name="league"
              variant="outlined"
              size="small"
              label="League"
              value={tempFilters.league}
              onChange={(event) => handleFiltersChange(event)}
            />
            <TextField
              name="competition"
              variant="outlined"
              size="small"
              label="Competition"
              value={tempFilters.competition}
              onChange={(event) => handleFiltersChange(event)}
            />
            <FormControl sx={{ width: 195 }} style={{ textAlign: "left" }}>
              <TextField
                select
                value={tempFilters.status}
                label="Status"
                name="status"
                variant="outlined"
                size="small"
                onChange={(event) => {
                  handleFiltersChange(event);
                }}
              >
                {statusList.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <TextField
              name="url"
              variant="outlined"
              size="small"
              label="Url"
              value={tempFilters.url}
              onChange={(event) => handleFiltersChange(event)}
            />
            <Button variant="contained" onClick={() => handleSearchClick()}>
              Search
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleResetFilters()}
            >
              Reset
            </Button>
          </div>
          <Box sx={{ height: "700px", width: "100%" }}>
            <DataGrid
              rows={events}
              columns={columns}
              rowCount={total}
              pageSize={pageSize}
              page={page}
              paginationMode="server"
              onPageChange={setPage}
              onPageSizeChange={handlePageSizeChange}
              rowsPerPageOptions={[25, 50, 100]}
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
              disableSelectionOnClick
              disableColumnFilter
              experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
        </div>
      )}
    </div>
  );
}
